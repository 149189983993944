<template>
  <div>
    <div class="banner">
      <div class="title">Tensor Mini</div>
    </div>

    <div class="productInfo">
      <div class="title">{{ $t('产品简介') }}</div>
      <div class="info" :class="$i18n.locale == 'en' ? 'en_info' : 'zh_info'">
        {{
          $t(
            'Tensor Mini是一款能用于室内外全场景的高精度3D相机，可用于精确定位与导航等场景。搭载自研3D成像核心算法，2米距离内可以达到毫米级深度误差，近距离可以达到亚毫米级深度误差。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。'
          )
        }}
      </div>
    </div>

    <div class="productNumber">
      <div class="title">{{ $t('产品参数') }}</div>
      <div class="img">
        <img
          v-show="$i18n.locale == 'zh'"
          src="../../../assets/TensorMini/mini参数.jpg.png"
          alt=""
        />
        <img
          v-show="$i18n.locale == 'en'"
          src="../../../assets/TensorMini/mini参数en.jpg.png"
          alt=""
        />
      </div>
    </div>

    <div class="plasticBall">
      <div class="title">{{ $t('成像效果-塑料球') }}</div>
      <div class="img">
        <img src="../../../assets/TensorMini/塑胶球.png" alt="" />
      </div>
    </div>

    <div class="ceramicBowl">
      <div class="title">{{ $t('成像效果-陶瓷碗') }}</div>
      <div class="img">
        <img src="../../../assets/TensorMini/碗.png" alt="" />
      </div>
    </div>

    <div class="floorTile">
      <div class="title">{{ $t('成像效果-地面砖') }}</div>
      <div class="img">
        <img src="../../../assets/TensorMini/瓷砖.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.banner {
  height: 480px;
  background: url(../../../assets/TensorMini/首页配图.png) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  .title {
    color: #344f98;
    font-size: 72px;
    font-weight: 700;
    line-height: 104.26px;
  }
}
.productInfo {
  padding-top: 51px;
  height: 280px;
  background: #f5f6fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: #344f98;
    line-height: 43.44px;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  .zh_info {
    text-align: justify;
    color: #808080;
    width: 1119px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.96px;
  }
  .en_info {
    text-align: justify;
    color: #808080;
    width: 1119px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.06px;
  }
}

.productNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 48px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 1119px;
    height: 522px;
    margin-bottom: 65px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.plasticBall {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f6fa;
  .title {
    margin-top: 37px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 983px;
    height: 319px;
    margin-bottom: 75px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.ceramicBowl {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  .title {
    margin-top: 37px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 983px;
    height: 319px;
    margin-bottom: 75px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.floorTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f6fa;
  .title {
    margin-top: 37px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 983px;
    height: 319px;
    margin-bottom: 75px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
